import React, { useRef, useEffect, memo } from "react";

import { useImmer } from "use-immer";

import isEqual from "lodash/isEqual";

import { createAvatarName, createJoinedText } from "@/helpers/avatar";
import onWindowResize from "@/helpers/onWindowResize";

function AvatarName({ className, children, showJoined }) {
	const canvasRef = useRef(null);

	const [state, updateState] = useImmer({ width: 0, height: 0 });

	useEffect(() => {
		const resize = () => {
			if (canvasRef.current) {
				updateState((draft) => {
					draft.width = canvasRef.current.offsetWidth;
					draft.height = canvasRef.current.offsetHeight;
				});
			} else {
				updateState((draft) => {
					draft.width = draft.height = 0;
				});
			}
		};

		resize(); // Initial resize

		return onWindowResize(resize);
	}, []);

	useEffect(() => {
		if (canvasRef.current) {
			const width = state.width;
			const height = state.height;

			const canvas = canvasRef.current;
			canvas.width = width;
			canvas.height = height;

			const context = canvas.getContext("2d");
			context.clearRect(0, 0, width, height);
			createAvatarName(children, context, width, height);
			if (showJoined) {
				createJoinedText("Joined", context, width, height);
			}
		}
	}, [state.width, state.height, children, showJoined]);

	return <canvas ref={canvasRef} className={className} />;
}

const MemorizedAvatarName = memo(AvatarName, isEqual);
export default MemorizedAvatarName;
