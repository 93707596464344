import React, { forwardRef, useEffect, useRef, useState, memo, useMemo } from "react";

import isFinite from "lodash/isFinite";
import isPlainObject from "lodash/isPlainObject";
import isEqual from "lodash/isEqual";
import isArray from "lodash/isArray";
import isString from "lodash/isString";

import AvatarName from "@/components/AvatarName";
import RoundButton from "@/components/interactives/RoundButton";
import ShuffleIcon from "@/images/icons/shuffle.svg";
import CorrectIcon from "@/images/icons/icon-correct-multicolor.svg";
import HostIcon from "@/images/icons/icon-label-host.svg";

import { tailwindCascade } from "@/helpers/tailwindCascade";

import { ANSWER1, ANSWER2, ANSWER3, ANSWER4, BLACK, GREEN_LIGHT, WHITE } from "@/colors";

import {
	CDN_BASE_URL,
	PLAYING_HOST_CONNECTION_ID,
	PLAYER_AVATAR_BACKGROUND_COLOR,
	PLAYER_AVATAR_BASE_PATH,
	PLAYER_AVATAR_CANVAS_SIZE,
} from "@/constants";
import Header from "./Header";

const AVATAR_CACHE_BURST = 1000 * 10; // Reset browser cache every 10 seconds

const AvatarFragment = memo(function AvatarFragment({ avatar = null, disableBackground = false, onLoad = null }) {
	useEffect(() => {
		if (onLoad) {
			onLoad();
		}
	}, [onLoad]);

	const style = useMemo(
		() => (disableBackground ? {} : { backgroundColor: PLAYER_AVATAR_BACKGROUND_COLOR }),
		[disableBackground]
	);

	return (
		<div className="pb-full relative w-full" style={style}>
			{isArray(avatar) ? (
				<svg
					className="absolute top-0 left-0 w-full h-full"
					viewBox={`0 0 ${PLAYER_AVATAR_CANVAS_SIZE} ${PLAYER_AVATAR_CANVAS_SIZE}`}
					fill="none"
					strokeLinecap="round"
					strokeLinejoin="round"
				>
					{avatar.map((layer, index) => (
						<g key={index} transform={`translate(${layer.x} ${layer.y}) scale(${layer.z} ${layer.z})`}>
							<image
								width={PLAYER_AVATAR_CANVAS_SIZE}
								height={PLAYER_AVATAR_CANVAS_SIZE}
								href={PLAYER_AVATAR_BASE_PATH + layer.url}
							/>
						</g>
					))}
				</svg>
			) : isString(avatar) ? (
				<img
					className="absolute top-0 left-0 w-full h-full"
					src={`${CDN_BASE_URL}/${avatar}?cb=${Math.round(Date.now() / AVATAR_CACHE_BURST)}`}
					alt=""
				/>
			) : null}
		</div>
	);
}, isEqual);

const Avatar = forwardRef(function Avatar(
	{
		playerAvatar,
		playerName,
		playerPoints,
		playerConnectionId,
		showShuffle,
		onShuffle,
		onChangeNumberOfStyles,
		showName,
		showPoints,
		rank = undefined,
		className,
		selectedAnswer,
		correctness,
		checkmark = undefined,
		disableBackground = false,
		border = true,
		width = 1000,
		height = 1000,
		bgColor,
		onLoad = null,
		isLobby = false,
		hostLabel = true,
		hostBorder = true,
		showJoined = false,
		...props
	},
	ref
) {
	const avatarContainerRef = useRef(null);

	const avatar = useMemo(
		() => (isArray(playerAvatar) || isString(playerAvatar) ? playerAvatar : null),
		[playerAvatar]
	);

	const name = useMemo(() => playerName || "", [playerName]);
	const points = useMemo(() => playerPoints || 0, [playerPoints]);
	const backgroundColor = useMemo(
		() => (disableBackground ? "transparent" : bgColor || PLAYER_AVATAR_BACKGROUND_COLOR),
		[bgColor, disableBackground]
	);
	const showSelectedAnswer = useMemo(
		() => isFinite(selectedAnswer) && selectedAnswer >= 0 && selectedAnswer <= 3,
		[selectedAnswer]
	);
	const showCheckmark = useMemo(() => checkmark === true || correctness === 1, [checkmark, correctness]);
	const showHostLabel = useMemo(
		() => hostLabel && playerConnectionId === PLAYING_HOST_CONNECTION_ID,
		[hostLabel, playerConnectionId]
	);
	const showHostBorder = useMemo(
		() => (hostLabel || hostBorder) && playerConnectionId === PLAYING_HOST_CONNECTION_ID,
		[hostLabel, hostBorder, playerConnectionId]
	);

	const checkmarkStyle = useMemo(() => {
		const checkmarkStyle = {};

		if (showSelectedAnswer) {
			// Four buttons
			checkmarkStyle.fill = [ANSWER1, ANSWER2, ANSWER3, ANSWER4][selectedAnswer];
			checkmarkStyle.color = correctness === 1 ? BLACK : checkmarkStyle.fill;
		} else if (correctness === 1) {
			// Checkboxes, type answer
			checkmarkStyle.fill = WHITE;
			checkmarkStyle.color = BLACK;
		} else {
			// Lobby
			checkmarkStyle.fill = GREEN_LIGHT;
			checkmarkStyle.color = WHITE;
		}

		return checkmarkStyle;
	}, [correctness, selectedAnswer, showSelectedAnswer]);

	return (
		<div ref={ref} className={tailwindCascade("relative", "w-20", "h-20", className)} {...props}>
			<div className="absolute w-full h-full">
				<div
					className={tailwindCascade("relative w-full h-full transition-opacity rounded-full p-0", {
						"border-2 md:border-4 border-yellow-brown": border && (showHostLabel || showHostBorder),
						"bg-black p-[4%]": border,
						"opacity-40": isFinite(correctness) && correctness < 1,
					})}
				>
					<div className="relative w-full h-full overflow-hidden rounded-full">
						<div
							ref={avatarContainerRef}
							className="absolute block -left-[2px] -top-[2px] w-[calc(100%+4px)] h-[calc(100%+4px)]"
							style={{ backgroundColor }}
						>
							<AvatarFragment avatar={avatar} disableBackground={disableBackground} onLoad={onLoad} />
						</div>
					</div>
					{((showHostLabel && !showPoints) || (showHostLabel && points === 0)) && (
						<HostIcon className="left-1/2 -bottom-[5%] absolute w-[55%] transform -translate-x-1/2" />
					)}
					{showHostLabel && showPoints && points !== 0 && (
						<>
							<Header className="left-1/2 text-3xl -bottom-[10%] absolute uppercase transform -translate-x-1/2 select-none">
								{points}
							</Header>
							<HostIcon className="left-1/2 -bottom-[26%] absolute w-[55%] transform -translate-x-1/2" />
						</>
					)}
					{!showHostLabel && showPoints && (
						<Header className="left-1/2 text-3xl -bottom-[7%] absolute uppercase transform -translate-x-1/2 select-none">
							{points}
						</Header>
					)}
				</div>

				{showName && (
					<div
						className={tailwindCascade(
							"absolute h-0 transition-opacity -top-[11.25%] -left-[11.25%] w-[122.5%] pb-[122.5%]",
							{
								"opacity-40": isFinite(correctness) && correctness < 1,
							}
						)}
					>
						<AvatarName className="absolute top-0 left-0 w-full h-full" showJoined={showJoined}>
							{name}
						</AvatarName>
					</div>
				)}

				{(showSelectedAnswer || showCheckmark) && (
					<div className="absolute top-0 left-0 w-full h-full">
						<div
							className={tailwindCascade("absolute bottom-0 right-0", {
								"w-8 pb-8": !isLobby,
								"w-[30%] pb-[30%] md:w-6 md:pb-6 lg:w-7 lg:pb-7": isLobby,
							})}
						>
							<div className="absolute top-0 left-0">
								<div
									className={tailwindCascade(
										"relative p-0.5 overflow-hidden bg-black rounded-full transition-transform transform",
										{ "scale-150": correctness === 1 }
									)}
								>
									<CorrectIcon className="w-full h-full" style={checkmarkStyle} />
								</div>
							</div>
						</div>
					</div>
				)}

				{isPlainObject(correctness) && (
					<div className="absolute bottom-0 right-0 p-1 overflow-hidden bg-black rounded-full">
						<div
							className={tailwindCascade(
								"flex items-center justify-center w-10 h-6 font-bold text-white",
								{
									"bg-yellow":
										correctness.numerator > 0 && correctness.numerator < correctness.denominator,
								},
								{ "bg-pink-light": correctness.numerator === 0 },
								{ "bg-green-light": correctness.numerator === correctness.denominator }
							)}
						>
							{correctness.numerator}/{correctness.denominator}
						</div>
					</div>
				)}

				{rank && (
					<div className="border-1 absolute bottom-0 right-0 w-6 h-6 overflow-hidden font-medium text-center text-black bg-white rounded-full">
						{rank}
					</div>
				)}

				{showShuffle && onShuffle && (
					<div
						className="top-1/2 h-1/4 absolute right-0 w-1/4 transform translate-x-1/2 -translate-y-1/2"
						style={{ maxWidth: "6rem", maxHeight: "6rem" }}
					>
						<RoundButton
							color="cyan"
							border={true}
							className="w-full h-full p-2 text-white"
							onClick={onShuffle}
						>
							<ShuffleIcon className="w-full" />
						</RoundButton>
					</div>
				)}
			</div>
		</div>
	);
});

const MemorizedAvatar = memo(Avatar, isEqual);
export default MemorizedAvatar;
