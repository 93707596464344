import React, { forwardRef } from "react";
import Button from "@/components/interactives/Button";

import { tailwindCascade } from "@/helpers/tailwindCascade";

export default forwardRef(function RoundButton({ children, className, ...props }, ref) {
	return (
		<Button className={tailwindCascade("px-4", "py-4", className)} borderRadius={9999} {...props}>
			{children}
		</Button>
	);
});
